.loginScreen.cmsScreen {
  background: white !important;
}

.ant-layout {
  background: unset;
}

.pageContainer {
  margin: 0 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.pageContainer .loginContainer {
  background-color: white;
  border-radius: 4px;
  max-width: 450px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: left;
      -ms-flex-align: left;
          align-items: left;
  padding: 40px;
}

.pageContainer .loginContainer .loginTitle {
  font-size: 30px;
  color: #333333;
  margin-bottom: 30px;
}

.pageContainer .loginContainer .forgotPasswordText {
  color: #333333;
  font-size: 15px;
  margin-bottom: 20px;
}

.pageContainer .loginContainer .loginFormItem {
  margin-bottom: 20px;
}

.pageContainer .loginContainer .forgotPassword {
  text-align: right;
  color: #0090ff;
  font-size: 14px;
}

.pageContainer .loginContainer .forgotPassword span {
  cursor: pointer;
}

.pageContainer .loginContainer .loginButton {
  height: 50px;
  margin-top: 20px;
}

.pageContainer .loginContainer .loginLogo {
  width: 50%;
  margin: 12px;
}

.pageContainer .otp_input_style {
  border: 0.75px solid lightgray;
  width: 40px !important;
  text-align: center;
  margin: auto;
  border-radius: 3px;
}
/*# sourceMappingURL=Login.module.css.map */